<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    width="40%"
    class="nsi-technical-reason"
    :close-on-click-modal="false"
  >
    <div slot="title">
      Организации
      <template v-if="userCanEdit">
        <el-button v-if="!editMode" class="back_action_panel--first_element" @click="handleEdit" type="text">
          <i class="el-icon-edit"></i> Редактировать
        </el-button>
        <el-button v-if="editMode" class="back_action_panel--first_element custom-button " @click="handleSave" type="text">
           <SaveIcon /> Сохранить
        </el-button>
        <el-button @click="handleDelete(organization.id)" :disabled="organization.isAip || organization.id == null" type="text" icon="el-icon-delete">
          Удалить
        </el-button>
      </template>
    </div>
    <el-form :rules="rules" v-loading="loading" ref="organizationForm" :model="organization" label-width="18rem">
      <el-form-item prop="isAip" label="СК-11:">
        <el-checkbox
          v-model="organization.isAip"
          disabled>
        </el-checkbox>
      </el-form-item>
      <el-form-item prop="analysisIds" label="Идентификатор ПК &quot;Анализ 2009&quot;:">
        <el-select
          v-model="organization.analysisIds"
          multiple
          filterable
          allow-create
          default-first-option
          placeholder=""
          :disabled="!editMode">
        </el-select>
      </el-form-item>
      <el-form-item prop="name" label="Наименование:">
        <el-input v-model="organization.name" :disabled="!editMode || organization.isAip"></el-input>
      </el-form-item>
      <el-form-item prop="parentId" label="Родительская запись:">
        <remote-search-input
          v-model="organization.parentId"
          searchUrl="/api/organizations/SearchByName"
          id="energy_object-modal--org_name_input"
          return-prop="id"
          size="large"
          :allow-create="false"
          :show-when-init="true"
          :initial-value="organization.parentIdName"
          :start-length=3
          :disabled="!editMode || organization.isAip"
        />
      </el-form-item>
      <el-form-item prop="organizationTypeId" label="Тип:">
<!--        <remote-search-input
            v-model="organization.organizationTypeId"
            searchUrl="/api/organizations/GetOrganizationTypes"
            size="large"
            label="name"
            return-prop="id"
            :show-when-init="true"
            :initial-value="organization.organizationTypeName"
            :start-length=0
            :disabled="!editMode"
          >
          </remote-search-input>-->
        <el-select v-model="organization.organizationTypeId" size="large" :disabled="!editMode || organization.isAip">
          <el-option
            v-for="item in organizationTypes"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import organizationsApi from '@/api/nsi/organizations';
import SaveIcon from '@/assets/icons/save.svg';
import validationRules from '@/mixins/validationRules';
import remoteSearchInput from '@/components/Ui/RemoteSearchInput';

export default {
  name: 'OrganizationModal',
  props: ['value', 'organization'],
  mixins: [validationRules],
  components: {
    remoteSearchInput, SaveIcon
  },
  computed: {
    ...mapGetters('identity', ['userHasRole']),
    ...mapGetters('dictionaries', ['organizationTypes']),
    userCanEdit() {
      return this.userHasRole('NsiCorpEdit,NsiFullAccess');
    },
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        if (!val) this.editMode = false;
        this.$emit('input', val);
      },
    },
  },
  async mounted() {
    this.$root.$on('addEvent', (x) => {
      this.editMode = x.editMode;
    });
  },
  data() {
    const parentValidation = (rule, value, callback) => {
      if (this.organization.id
        && this.organization.parentId
        && this.organization.id === this.organization.parentId) {
        return callback(new Error('Запись не может быть родительской по отношению к себе'));
      } else {
        return callback();
      }
    };

    return {
      loading: false,
      editMode: false,
      rules: {
        name: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
        organizationTypeId: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
        ],
        parentId: [
          { validator: parentValidation, trigger: 'blur' }
        ]
      },
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    async addOrganization(organization) {
      this.loading = true;
      const res = await organizationsApi.addOrganization(organization);
      if (!res.data.succeeded) {
        this.loading = false;
        this.$alert(res.data.resultMessage, 'Ошибка', {
          confirmButtonText: 'OK',
          type: 'warning',
          customClass: 'alert-30',
        });
      } else {
        this.dialogVisible = false;
        this.loading = false;
        this.$emit('on-create');
      }
    },
    async updateOrganization(organization) {
      this.loading = true;
      const res = await organizationsApi.updateOrganization(organization);
      if (!res.data.succeeded) {
        this.loading = false;
        this.$alert(res.data.resultMessage, 'Ошибка', {
          confirmButtonText: 'OK',
          type: 'warning',
          customClass: 'alert-30',
        });
      } else {
        this.dialogVisible = false;
        this.loading = false;
        this.$emit('on-update');
      }
    },
    async validateForm() {
      const valid = await this.$refs.organizationForm.validate();
      return valid;
    },
    async handleSave() {
      const valid = await this.validateForm();
      if (!valid) return;

      const request = this.organization.id ? this.updateOrganization : this.addOrganization;
      this.loading = true;
      await request(this.organization);  
    },
    handleEdit() {
      this.editMode = true;
    },
    handleDelete(id) {
        this.$confirm('Вы действительно хотите удалить запись?', 'Удаление записи!', {
          confirmButtonText: 'ОК',
          cancelButtonText: 'Отмена',
          type: 'warning',
        }).then(async () => {
          this.loading = true;

          const res = await organizationsApi.deleteOrganization(id);
          if (!res.data.succeeded) {
            this.$alert(res.data.resultMessage, 'Ошибка', {
              confirmButtonText: 'OK',
              type: 'warning',
              customClass: 'alert-30',
            });
          } else {
            this.$emit('on-delete');
            this.dialogVisible = false;
          }

          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
